import {get, post, patch, httpDelete, download, getBlob } from './axiosReq'
// demo
export const apiAddress = params => post('qeda/v1/users/my_address/address_edit_before', params);
export const getFileList = params => get(`qeda/file/file/?project=${params.project}&file_type=${params.file_type}`);
export const getProject = (projectId, params) => get(`qeda/file/project/${projectId}/`, params);
export const getFile = (fileId, params) => get(`qeda/file/file/${fileId}/`, params);
export const getComponentList = params => get(`qeda/file/component/`, params);
export const getCustomComponentList = params => get(`qeda/file/custom-component/`, params);
export const getComponent = (componentId, params) => get(`qeda/file/component/${componentId}/`, params);
export const createProject = (params) => post(`qeda/file/project/`, params);
export const createFile = (params) => post(`qeda/file/file/`, params);
export const patchFile = (fileId, params) => patch(`qeda/file/file/${fileId}/`, params);
export const getRecentFile = () => get(`qeda/file/file-recent/`);
export const getExportGDS = (fileId, params) => download(`qeda/file/${fileId}/export-gds/`, params);
// export const postImportGDS = (params) => post(`qeda/file/import-gds/`, params);
export const postImportGDS = (params) => post(`qeda/file/import-gds-svg/`, params);
export const postComponentPreview = (params) => post(`qeda/file/component-preview/`, params);
export const translateShape = (params) => post(`/qeda/tool/translate/`, params);
export const deleteFile = (fileId) => httpDelete(`qeda/file/file/${fileId}/`);
export const deleteComponent = (componentId) => httpDelete(`qeda/file/component/${componentId}/`);
export const postSetCellFileASLayoutFile = (params) => post(`qeda/file/set-cell-as-file/`, params);
export const postSetCellFileASComponent = (params) => post(`qeda/file/set-cell-as-component/`, params);
export const postSetLayoutFileAsCellFile = (params) => post(`qeda/file/set-layout-as-cell/`, params);
export const postCopyCellFile = (params) => post(`qeda/file/copy-cell-file/`, params);
export const patchComponent = (componentId, params) => patch(`qeda/file/component/${componentId}/`, params);
export const createCell = (params) => post(`qeda/file/cell/`, params);
export const patchCell = (cellId, params) => patch(`qeda/file/cell/${cellId}/`, params);
export const getCell = (cellId, params) => get(`qeda/file/cell/${cellId}/`, params);
// 获取器件列表
export const getDeviceList = (type) => get(`qeda/file/common-component/`, type);
// 修改密码
export const patchChangePassword = (params) => patch(`qeda/user/change-password/`, params);
// 退出登录
export const postExit = (params) => post(`qeda/user/logout/`, params);

// 个人中心api
export const getUserDisplay = () => get(`qeda/usercenter/user_display/`);
export const updateUserDisplay = (params) => post(`qeda/usercenter/user_display/`, params);
export const getUserAllproject = (params) => post(`qeda/usercenter/user_project/`, params);
export const getTeamList = (params) => post(`qeda/team/team_list/`, params);
export const createTeams = (params) => post(`qeda/team/team/`, params);
export const getTeamProjectList = (params) => post(`qeda/team/team_project/`, params);
export const getTeamInciteCode = (params) => post('/qeda/team/team_invitation_code/', params);
export const getTeamInviteConfirm = (params) => get(`qeda/team/team_invitation_code/?code=${params}`);
export const getMemberOfTeam = (params) => post(`qeda/team/team_number_list/`, params);
export const transferTeam = (params) => post(`qeda/team/team_transfer_user/`, params);
export const quitTeam = (params) => post(`qeda/team/team_exit_user/`, params);
export const getMemberOfTeamProject = (params) => post(`qeda/team/team_project_users/`, params);
export const getUserRoleTeamProject = (params) => post(`qeda/team/team_user_roles/`, params);
export const getAllCooperater = (params) => post(`qeda/team/team_project_list/`, params);
export const addCooperater = (params) => post(`qeda/team/team_add_view/`, params);
export const delMember = (params) => post(`qeda/team/batch_delete_project_users/`, params);
export const updateMemberPower = (params) => post(`qeda/team/batch_update_project_users_permission/`, params);
export const getUserRole = (params) => get(`qeda/team/get_user_role/?snow_team_id=${params}`);
export const batchCopyProject = (params) => post(`qeda/team/copy_project/`, params);
export const batchCopyProjectToPerson = (params) => post(`qeda/team/copy_project_to_user/`, params);
export const getShareTeamProjectCode = (params) => post(`qeda/team/project_share_user/`, params);
export const receiveShareCode = (params, gns, target) => get(`qeda/team/project_share_user/?code=${params}&project_gns=${gns}&target=${target}`);
export const updateTeamName = (params) => post(`qeda/team/update_team_name/`, params);
export const getProjectPermissions = (params) => post(`qeda/team/project_permission/`, params);
export const deleteProject = (params) => post(`qeda/team/delete_project_gns/`, params);
export const batchTeamRole = (params) => post(`qeda/team/batch_team_role/`, params);
export const batchProjectPermission = (params) => post(`qeda/team/batch_project/`, params);
export const batchDeleteMember = (params) => post(`qeda/team/batch_delete_member/`, params);
export const batchDevicePower = (params) => post(`qeda/team/batch_component_permission/`, params);
export const updateMemberEdit = (params) => post(`qeda/team/update_user_message/`, params);
export const uploadDevice = (params) => post(`qeda/component/create_public_component/`, params);
export const getTeamDevice = (params) => post(`qeda/component/team_component_list/`, params);
export const dissolutionTeam = (params) => post(`qeda/team/dissolution/`, params);
export const deleteDevice = (params) => post(`qeda/component/delete_component/`, params);
export const getUserDeviceList = (params) => post(`/qeda/component/user_component_list/`, params);
export const getDeviceMine = (params) => post(`qeda/component/components_my_list/`, params);
export const getDeviceTeam = (params) => post(`qeda/component/components_team_list/`, params);
export const getDevicePublic = (params) => post(`qeda/component/components_public_list/`, params);
export const getDeviceOfficial = (params) => post(`qeda/component/components_official_list/`, params);
export const getDeviceInfo = (params) => post(`qeda/component/get_component_detail/`, params);
export const updateDeviceNote = (params) => post(`qeda/component/edit_component_notes/`, params);
export const getTeamViewer = (params) => post(`qeda/team/team_user_role_viewer/`, params);
export const getTeamViewerNote = (params) => post(`qeda/team/team_users_note/`, params);
export const getMarkImg = (params) => post(`qeda/component/get_mark_img/`, params);

export const deleteSymbol = (params) => post(`qeda/symbols/delete_symbol/`, params);
export const getUserSymbol = (params) => post(`qeda/symbols/user_symbols/`, params);
export const getTeamSymbol = (params) => post(`qeda/symbols/team_symbols/`, params);
export const getSymbolMine = (params) => post(`qeda/symbols/symbols_my_list/`, params);
export const getSymbolTeam = (params) => post(`qeda/symbols/symbols_team_list/`, params);
export const getSymbolPublic = (params) => post(`qeda/symbols/symbols_public_list/`, params);
export const getSymbolOfficial = (params) => post(`qeda/symbols/symbols_official_list/`, params);
export const getSymbolInfo = (params) => post(`qeda/symbols/symbols_detail/`, params);
export const updateSymbolNote = (params) => post(`qeda/symbols/edit_symbols_notes/`, params);
export const uploadSymbol = (params) => post(`qeda/symbols/save_public_symbol/`, params);

export const copyFiles = (params) => post(`qeda/team/copy_files_to_project/`, params);
export const deleteFiles = (params) => httpDelete(`/qeda/file/del_batch_projects/`, params);
export const getPermissionOfLibrary = (params) => post(`qeda/symbols/get_symbol_component_permission/`, params);

// 2.4版本器件库
export const createLibrary = (params) => post(`qeda/component/create_library/`, params);
export const getLibraryList = (params) => post(`qeda/component/get_library_list/`, params);
export const updateLibraryName = (params) => post(`qeda/component/rename_library/`, params);
export const getPersonalLibraryComponent = (params) => post(`qeda/component/components_my_list/`, params);
export const getTeamLibraryComponent = (params) => post(`qeda/component/add_library_team_component_list/`, params);
export const getPublicLibraryComponent = (params) => post(`qeda/component/components_public_list/`, params);
export const getOfficialLibraryComponent = (params) => post(`qeda/component/components_official_list/`, params);
export const getPDKLibraryComponent = (params) => post(`qeda/component/pdk_component_list/`, params);
export const getTeamComponent = (params) => post(`qeda/component/team_component_list/`, params);
export const getToolTeamLibraryComponent = (params) => post(`qeda/component/components_team_list/`, params);
export const batchAddComponent = (params) => post(`qeda/component/batch_add_component/`, params);
export const batchAddTeamComponent = (params) => post(`qeda/component/batch_add_team_component/`, params);
export const batchExportLibrary = (params) => post(`qeda/component/export_library_file/`, params);
export const deleteComponentLibrary = (params) => post(`qeda/component/delete_library/`, params);


// 2.4版本符号库
export const createSymbol = (params) => post(`qeda/symbols/create_library/`, params);
export const getSymbolList = (params) => post(`qeda/symbols/get_library_list/`, params);
export const updateSymbolName = (params) => post(`qeda/symbols/rename_library/`, params);
export const batchExportToZip = (params, headers) => getBlob(`file/api/v1/get_object_data_zip/`, params, headers);
export const getPersonalLibrarySymbol = (params) => post(`qeda/symbols/user_symbols/`, params);
export const getTeamLibrarySymbol = (params) => post(`qeda/symbols/add_library_team_symbol_list/`, params);
export const getPublicLibrarySymbol = (params) => post(`qeda/symbols/symbols_public_list/`, params);
export const getOfficialLibrarySymbol = (params) => post(`qeda/symbols/symbols_official_list/`, params);
export const getPDKLibrarySymbol = (params) => post(`qeda/symbols/pdk_symbol_list/`, params);
export const batchAddSymbol = (params) => post(`qeda/symbols/batch_add_symbol/`, params);
export const batchAddTeamSymbol = (params) => post(`qeda/symbols/batch_add_team_symbol/`, params);
export const deleteSymbolLibrary = (params) => post(`qeda/symbols/delete_library/`, params);

// cookie
export const saveUserCookie = (params) => get(`qeda/user/get_user_cookie/`, params);

//pcell
export const createPcell = (params) => post(`qeda/component/add_user_pcell/`, params);
export const editPcell = (params) => post(`qeda/component/edit_user_pcell/`, params);
export const deletePcell = (params) => post(`qeda/component/delete_user_pcell/`, params);
export const getPcellList = (params) => post(`qeda/component/get_user_pcell/`, params);
export const importPcell = (params) => post(`qeda/component/import_user_pcell/`, params);
export const savePcellJs = (params) => post(`file/api/v1/put_ordinary_file/`, params);

//drc
export const createDrc = (params) => post(`qeda/component/add_user_drc/`, params);
export const editDrc = (params) => post(`qeda/component/edit_user_drc/`, params);
export const deleteDrc = (params) => post(`qeda/component/delete_user_drc/`, params);
export const getDrcList = (params) => post(`qeda/component/get_user_drc/`, params);
export const importDrc = (params) => post(`qeda/component/import_user_drc/`, params);
export const loadPDK = (params) => post(`qeda/symbols/save_pdk_info/`, params);
export const getOfficialPDKInfo = (params) => post(`qeda/symbols/get_pdk_file/`, params);

export const FILE_TYPE_LAYOUT = 1;
export const FILE_TYPE_CELL = 2;
export const MATERIALS_TYPE_SUPERCONDUCTION = 1;
export const MATERIALS_TYPE_SEMICONDUCTOR = 2;